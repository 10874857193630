.homepage {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.category-slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.category-slide.active {
  opacity: 1;
}

.category-name {
  position: absolute;
  bottom: 40px;
  left: 40px;
  color: white;
  font-size: 1rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.homepage img {
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, 0);
}

@media (max-width: 768px) {
  .category-name {
    bottom: 20px;
    left: 20px;
 
  }
}
