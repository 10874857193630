.about-container {
  min-height: 100vh;
  padding: 80px 20px;
  background-color: #909090;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-content {
  max-width: 1200px;
  display: flex;
  gap: 60px;
  align-items: flex-start;
  padding: 20px;
}

.profile-image {
  flex: 0 0 400px;
  padding-top: 40px;
}

.profile-image img {
  width: 100%;
  height: auto;
  border-radius: 4px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}

.bio-content {
  flex: 1;
  padding: 20px 0;
}

.bio-content h1 {
  font-size: 2rem;
  margin-bottom: 10px;
  margin-top: 0px;
  padding-top: 0px;
  font-weight: 300;
  color: black;
}

.bio-content p {
  font-size: 0.9rem;
  line-height: 1.8;
  margin-bottom: 20px;
  color: black;
}

@media (max-width: 968px) {
  .about-content {
    flex-direction: column;
    gap: 40px;
  }

  .profile-image {
    flex: 0 0 auto;
    max-width: 100%;
  }
}

@media (max-width: 480px) {
  .about-container {
    padding: 60px 15px;
  }

  .bio-content h1 {
    font-size: 2rem;
  }

  .bio-content p {
    font-size: 1rem;
  }
}

.social-links {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding-top: 20px;
}

.social-links a {
  transition: all 0.3s ease;
}

.social-links a:hover {
  transform: scale(1.2);

}