.portfolio-page {
    padding-top: 0px; /* Space for fixed navigation */
    min-height: 100vh;
    background-color: white;
  }
  
  .gallery-container {
    width: 100%;
    padding: 0;
  }
  
  .pswp-gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 0;
    width: 100%;
  }
  
  .gallery-item {
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .gallery-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    transition: transform 0.3s ease;
  }
  
  .gallery-item img:hover {
    cursor: pointer;
  }
  
  @media (min-width: 2000px) {
    .pswp-gallery {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  @media (max-width: 1500px) {
    .pswp-gallery {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 1000px) {
    .pswp-gallery {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 600px) {
    .pswp-gallery {
      grid-template-columns: 1fr;
    }

    .gallery-item img {
      width: 100%;
      height: auto;
      object-fit: cover;
      display: block;
      transition: transform 0.3s ease;
    }
  }
  
  /* Remove the media queries as react-masonry-css handles the breakpoints */
  

  