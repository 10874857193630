@import url('https://fonts.googleapis.com/css2?family=Geist:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Geist+Mono:wght@100..900&family=Geist:wght@100..900&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Geist mono;
}

.App {
  background-color: white;
  min-height: 100vh;
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  position: relative;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Add these styles after your existing .App styles */

/* For Webkit browsers (Chrome, Safari, etc) */
::-webkit-scrollbar {
  width: 8px;  /* Make scrollbar thinner */
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.6);  /* Subtle track color */
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.4);  /* Darker thumb color */
  border-radius: 4px;  /* Rounded corners */
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4);  /* Slightly darker on hover */
}

/* For Firefox */
* {
  scrollbar-width: thin;  /* Make scrollbar thinner */
  scrollbar-color: rgba(0, 0, 0, 0.6) rgba(0, 0, 0, 0.3);  /* thumb and track colors */
}
